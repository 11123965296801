import React from "react"
import Container from "@ecom/ui/components/Container"
import cn from "classnames"
import { CupcakeImg, ThreePercentImg, CardsSvg, HeadphonesImg, ShopSvg, WalletSvg } from "./img"

import * as styles from "./betterHalva.module.scss"

type Props = {
  title?: string
  orderNum?: string
  titleFullWidth?: boolean
}

const defaultTitle = "Почему карта «Халва» лучше кредитной карты?"

export const BetterHalva = ({ orderNum, title = defaultTitle, titleFullWidth = false }: Props) => (
  <section className={styles.section} data-exclude={orderNum}>
    <Container>
      <h2 className={cn(styles.head, titleFullWidth && styles.headFullWidth)}>{title}</h2>
      <div className={styles.grid}>
        <div className={styles.block1}>
          <h3 className={styles.title}>Кэшбэк до 10% с подпиской «Халва.Десятка»</h3>
          <ShopSvg className={styles.icon} />
          <HeadphonesImg alt="headphones" className={styles.img} objectPosition="top left" />
        </div>
        <div className={styles.block2}>
          <h3 className={styles.title}>
            До +3%
            <br />к ставке вклада
          </h3>
          <WalletSvg className={styles.icon} />
          <ThreePercentImg alt="three percent" className={styles.img} />
        </div>
        <div className={styles.block3}>
          <h3 className={styles.title}>
            Снятие наличных в&nbsp;рассрочку <br /> на 3 месяца
          </h3>
          <CardsSvg className={styles.icon} />
          <CupcakeImg alt="cake" className={styles.img} objectPosition="top left" />
        </div>
      </div>
    </Container>
  </section>
)
