import React, { useEffect, useReducer } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/KartaSvoboda"
import { WhatAreTheBenefits } from "../components/WhatAreTheBenefits"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { CardWithMaxBenefits } from "../components/CardWithMaxBenefits"
import { BetterHalva } from "../components/BetterHalva"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { MoreBenefits } from "../components/MoreBenefits"
import { Partners } from "../components/PartnersNew"
import { Reviews } from "../components/Reviews"
import { Questions } from "../components/Questions"
import { NewFooter } from "../components/NewFooter"

import { getPageData } from "../helpers/getPageData"

import { PageData } from "../interfaces/pageProps"
import { sendScrollEventToDataLayer } from "../helpers/sendScrollEventToDataLayerPkw"
import { QUESTIONS_NEW } from "../components/Questions/helpers"

const newWhatsAreTheBenefitsText = (
  <>Специальные предложения позволяют оформить рассрочку на 300 дней без&nbsp;переплат</>
)

const whatAreTheBenefitsPrice = (
  <>
    <span>357</span> ₽ / день
  </>
)

const whatAreTheBenefitsTitle = (
  <>
    300 дней <br /> без переплат
  </>
)

const titleForm = (
  <>
    Оформите карту «Халва» <br /> и получите подписку <br /> «Халва.Десятка» в подарок
  </>
)

const IDS = ["formPkw", "textBottomCalculator", "partners", "questions", "footer"]

type ObserverType = {
  formPkw: boolean
  textBottomCalculator: boolean
  partners: boolean
  questions: boolean
  footer: boolean
}

function observerReducer(state: ObserverType, action: any) {
  if (IDS.includes(action.id)) {
    return { ...state, [action.id]: action.value }
  }
  throw new Error()
}

const initialObserverState: ObserverType = {
  formPkw: false,
  textBottomCalculator: false,
  partners: false,
  questions: false,
  footer: false,
}

const seoData = {
  title: "Карты рассрочки ООО «Хоум Кредит энд Финанс Банк» станут картами «ХАЛВА»",
}

// TODO: Пока Новую длинную заявку надо показывать только на тесте + там он еще пройдет через АБ тест
// пока поставлю переменную только, придется поменять на тип того - globalThis.KameleeonExperiment(потом)

const longForm = process.env.GATSBY_ENV === "test"

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const [observerState, observerDispatch] = useReducer(observerReducer, initialObserverState)

  const callback = (entries: IntersectionObserverEntry[]) => {
    for (let index = 0; index < entries.length; index += 1) {
      const element = entries[index]
      const { id } = element.target
      observerDispatch({ id, value: element.isIntersecting })
    }
  }

  useEffect(() => {
    Object.keys(observerState).forEach((key) => {
      if (observerState[key as keyof typeof initialObserverState]) {
        sendScrollEventToDataLayer(key)
      }
    })
  }, [observerState])

  useEffect(() => {
    const isSupported =
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    let observer: IntersectionObserver

    if (isSupported) {
      observer = new IntersectionObserver(callback)

      IDS.forEach((id) => {
        const element = document.getElementById(id)
        if (element) {
          observer.observe(element)
        }
      })
    }

    return () => {
      if (isSupported) {
        IDS.forEach((id) => {
          const element = document.getElementById(id)
          if (element) {
            observer.unobserve(element)
          }
        })
      }
    }
  }, [])

  return (
    <Layout noHeader noFooter seoData={seoData} noIndex={noIndex}>
      <NewHeader showHint={false} hasCTA />
      <Banner orderNum="1" />
      <BetterHalva title="Почему карта «Халва» выгоднее?" titleFullWidth orderNum="2" />
      <CardWithMaxBenefits orderNum="3" />
      <FormPKW
        dataLayerName="shortPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        longForm={longForm}
        orderNum="4"
      />
      <HowInstallmentWorks orderNum="5" />
      <NewCalculator additionalEventInDataLayer orderNum="6" />
      <WhatAreTheBenefits
        title={whatAreTheBenefitsTitle}
        text={newWhatsAreTheBenefitsText}
        price={whatAreTheBenefitsPrice}
        anotherIndentTop
        orderNum="7"
      />
      <Partners orderNum="8" />
      <MoreBenefits orderNum="9" />
      <Reviews additionalEventInDataLayer orderNum="10" />
      <Questions questionList={QUESTIONS_NEW} orderNum="11" />
      <NewFooter ligal={ligal} orderNum="12" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/pkw/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
